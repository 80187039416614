enum Commune {
	EPO = "EPO",
	HNK = "HNK",
	KRN = "KRN",
	LHJ = "LHJ",
	RPO = "RPO",
	STO = "STO",
	INK = "INK",
	KAU = "KAU",
	PKA = "PKA",
	VHT = "VHT",	
	KRK = "KRK",
}

export default Commune;
