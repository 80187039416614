import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import "./styles/global.scss";

import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://fc10c50c5dcbf2497b6581465f725bec@o307173.ingest.sentry.io/4505623285071872",
  integrations: [
    new Sentry.BrowserTracing({
			// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", "https://hoivakodit.luvn.fi/"],
    }),
    new Sentry.Replay(),
  ],
  ignoreErrors: [
    /ResizeObserver loop completed with undelivered notifications./ // ignore the ResizeObserver error/warning from the mapbox-gl library
  ],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const myApp = <App />;


ReactDOM.render(myApp, document.getElementById("root"));
