import React, { FC } from "react";
import config from "./config";
import { Link } from "react-router-dom";
import { useT, useCurrentLanguage } from "../i18n";

const Footer: FC = () => {
	const currentLanguage = useCurrentLanguage();

	const linkPrivacy =
		currentLanguage === "sv-FI"
			? "https://www.luvn.fi/sv/ta-kontakt/dataskyddsbeskrivningar"
			: "https://www.luvn.fi/fi/ota-yhteytta/tietosuojaselosteet";

	const linkFeedback =
		currentLanguage === "sv-FI"
			? "https://www.luvn.fi/sv/kund-hos-oss/ge-respons"
			: "https://www.luvn.fi/fi/meilla-asiakkaana/anna-palautetta";

	return (
		<footer className="footer" style={{ borderTop: "1px solid #e8e8e8" }}>
			<div className="logo-container">
				<a href="/">
					<img
						className="logo-footer"
						src={
							config.PUBLIC_FILES_URL + "/LU_hyvinvointialue_logo_2022.png"
						}
						alt="Länsi-Uudenmaa sote logo"

					/>
				</a>
			</div>

			<nav className="footer-links">
				<span className="footer-link">
					<a
						href={linkPrivacy}
						target="_blank"
						rel="noopener noreferrer external"
					>
						{useT("footerLinkPrivacy")}
					</a>
				</span>
				<span className="footer-link">
					<Link to="/saavutettavuus">
						{useT("footerLinkAccessibility")}
					</Link>
				</span>
				<span className="footer-link">
					<a
						href={linkFeedback}
						target="_blank"
						rel="noopener noreferrer external"
					>
						{useT("footerLinkFeedback")}
					</a>
				</span>
			</nav>
		</footer>
	);
};

export default Footer;
